import React from "react";

const ServicesInfo = ({ items, className }) => {
  return (
    <div className={`grid grid-cols-2 gap-2.5 xl:gap-4 items-center p-5 bg-light-gray rounded-xl ${className || ''}`} tabIndex={0}>
      {items?.map((info, index) => (
        <div key={index} className="flex flex-row gap-2 align-center">
          <img
            loading="lazy"
            src={`data:image/svg+xml,${encodeURIComponent(info?.icon?.svg)}`}
            alt={info?.text}
            className="shrink-0 aspect-square object-contain object-center w-4 overflow-hidden"
          />
          <div className="text-soft-black text-sm self-stretch" aria-hidden={true}>{info.text}</div>
        </div>
      ))}
    </div>
  );
}

export default ServicesInfo;
