import React from "react";
import RichText from "../../rich-text";
import ProductSizing from "@components/product/size-guide";
import ShippingConditions from "../shipping-conditions";


const generateItems = ({
  shippingConditions,
  productDescription,
  productFeatures,
  crateSizeDetails,
  quizLink,
  shippingTimeEstimate, 
  backOrderDetailsClassName,
}) => {
  const items = [
    {
      title: "Details",
      filter: Boolean(productDescription),
      content: (
        <RichText
          className="mb-4 text-black"
          text={productDescription || []}
        ></RichText>
      ),
    },
    {
      title: "Features",
      filter: Boolean(productFeatures),
      content: (
        <RichText
          className="mb-4 text-black"
          text={productFeatures || []}
        ></RichText>
      ),
    },
    {
      title: "Sizing",
      filter: Boolean(crateSizeDetails),
      content: (
        <ProductSizing items={crateSizeDetails} quizLink={quizLink} />
      ),
    },
    {
      title: "Shipping & Returns",
      filter: Boolean(shippingConditions),
      content: (
        <>
          <ShippingConditions {...{ productEntry: { shippingTimeEstimate, backOrderDetailsClassName } }} />
          <RichText
            className="mb-4 text-black"
            text={shippingConditions || []}
          ></RichText>
        </>
      ),
    },
  ];

  return items.filter((item) => item.filter);
}

export default generateItems;
