import React, { useEffect, useState } from "react"
import Image from "@components/image";
import { onEnterKeyPress } from "@src/utils/general";
import { useProductStore } from "@src/hooks/useStore";

const ProductFeatures = ({ items }) => {

    const { currentVariant } = useProductStore(["currentVariant"]);
    const [activeItem, setActiveItem] = useState(null);

    useEffect(() => {
        setActiveItem(items[0])
    }, [items])

    return (
        <div className="flex flex-col gap-x-15 gap-y-5 w-full" aria-label="Product Features" aria-hidden={false} tabIndex={0}>
            <div className="product-features antialiased">
                {items?.map(({ title }) => (
                    <p
                        key={`heading-${title}`}
                        className={`
                            ${activeItem?.title === title
                                ? 'product-features-item--active'
                                : 'product-features-item--inactive'
                            } product-features-item`}
                        onClick={() => setActiveItem(items.find(item => item.title === title))}
                        onKeyDown={(e) => onEnterKeyPress(e, () => setActiveItem(items.find(item => item.title === title)))}
                        aria-label={`${title} Tab`}
                        tabIndex={0}
                    >
                        {title}
                    </p>
                ))}
            </div>
            <div className="flex w-full gap-x-12 mt-4 justify-between">
                <div className="col-span-12 md:col-span-6 w-full">
                    {activeItem?.content}
                </div>
                {(currentVariant?.listingImage || currentVariant?.variantImages?.[0]) && 
                <div className="hidden md:block md:w-[350px] md:h-[370px] flex-shrink-0">
                    <Image
                        image={currentVariant?.listingImage || currentVariant?.variantImages?.[0]}
                        width={350}
                        height={300}
                        className={'rounded-xl object-cover'}
                    />
                </div>}
            </div>
        </div>
    )
}

export default ProductFeatures