import ProductFeatures from '@components/product/features'
import getValue from "get-value"
import React from 'react'
import generateItems from "./helpers"
import { useProductStore } from '@src/hooks/useStore'

const ProductCopy = ({
  productEntry,
  productDescription,
  shippingConditions,
  productFeatures,
  variantCopy,
  crateSizes,
  quizLink,
}) => {

  const { currentVariant } = useProductStore(["currentVariant"]);

  const { shippingTimeEstimate, backOrderDetailsClassName } = productEntry;
  const selectedSize = getValue(currentVariant, "size")
  const selectedFlavor = getValue(currentVariant, "flavor")
  const selectedConfiguration = getValue(currentVariant, "configuration")
  const specificVariantCopy = variantCopy?.find(
    block => block.sizeFilter && block.sizeFilter === selectedSize || block.flavorFilter && block.flavorFilter === selectedFlavor || block.configurationFilter && block.configurationFilter === selectedConfiguration
  )

  if (specificVariantCopy) {
    const {
      productFeatures: variantProductFeatures,
      productDescription: variantProductDescription,
      shippingConditions: variantShippingConditions,
    } = specificVariantCopy

    productDescription = variantProductDescription || productDescription;
    productFeatures = variantProductFeatures || productFeatures;
    shippingConditions = variantShippingConditions || shippingConditions;
  }


  const items = generateItems({
    shippingConditions,
    productDescription,
    productFeatures,
    crateSizeDetails: crateSizes,
    quizLink,
    shippingTimeEstimate,
    backOrderDetailsClassName,
  });

  return (
    <ProductFeatures items={items} />
  )
}

export default ProductCopy