import React, { useState, useCallback } from 'react'
import CrateSize from "@components/crate-size-modal/crate-size"
import Link from '../../link'

const ProductSizing = ({ items, quizLink }) => {
  const [activeIndex, setActiveIndex] = useState(1)
  const setActiveItem = useCallback(
    index => {
      setActiveIndex(index)
    },
    [activeIndex]
  )

  return (
    <div className="relative z-20 m-auto bg-white rounded-xl max-h-screen max-w-[800px]">
        <div className="h-full max-h-screen overflow-x-hidden overflow-y-auto scrolling-touch">
          <div className="flex flex-col gap-5">
            <div className="flex w-full sm:justify-center items-center">
              <h2 className="w-full text-center text-2xl font-medium text-dark-navy tracking-normal" id="sizePopupHeading">
                Want to compare sizes?
              </h2>
            </div>
            <div className="flex items-center w-full flex-wrap xl:flex-nowrap">
              {items.map((item, index) => (
                <button className={`flex w-1/2 xl:w-full justify-center items-center border-b-2 ${
                    activeIndex === item?.variant?.id || activeIndex === index ? 'border-black' : 'border-gray'
                  }`}
                    
                  onClick={() => setActiveItem(item?.variant?.id ? item?.variant?.id : index)}
                  key={`size-${index}`}>
                  <p className="p-3 font-medium text-lg">
                    {item.label}
                  </p>
                </button>
              ))}
              </div>
            <div className="mb-4">
              {items.map((item, index) => (
                <CrateSize
                  {...{ index, ...item, isOpen: activeIndex === item?.variant?.id || activeIndex === index }}
                  key={`carteSize-${index}`}
                  item={item}
                ></CrateSize>
              ))}
            </div>

            <footer className="text-center">
              <div className="w-full flex justify-center items-center">
                <Link className="btn btn-secondary hover:bg-gray hover:text-soft-black hover:border-gray w-full justify-center" href={ quizLink } target="_blank">Take the Sizing Quiz</Link>
              </div>
            </footer>
          </div>
        </div>
      </div>
  )
}

export default ProductSizing