import React from "react"

const CrateSize = ({ item, isOpen }) => {
    const {
      intro,
      breeds
    } = item
  
    const dimensions = {
      internalDimensions: 'Internal Dimensions',
      collapsedDimensions: 'Collapsed Dimensions',
      externalDimensions: 'External Dimensions',
      createWeight: 'Crate Weight'
    }
  
    return (
      <div className={`flex flex-col w-full justify-center items-center gap-5 ${isOpen ? `mt-2` : `hidden`}`}>
        <p className="sm:mb-8 text-xl font-medium text-black">{intro}</p>
        <div className="flex flex-col justify-center items-center bg-[#F9F9F9] rounded-xl p-2.5 gap-2">
          <p className="uppercase text-[#59A458] text-base font-medium">
            Suggested Breeds:
          </p>
          <p className="text-base text-center">{breeds}</p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 w-full gap-5 text-center">
          {Object.entries(dimensions).map(([key, value], index) => (
            item[key] ? (
              <div className="flex flex-col gap-2 p-2.5  bg-[#F9F9F9] rounded-xl" key={`${key}-${index}`}>
                <p className="uppercase text-[#59A458] font-xl font-medium tracking-wider">{value}</p>
                <p className="color-black text-base">{item[key]}</p>
              </div>
            ) : null
          ))}
          </div>
      </div>
    )
  }

  export default CrateSize